<template>
  <el-form :model="data" class="mt-20" ref="tableForm">
    <el-table :data="data.productDtos" style="width: 100%" class="crm-details-operation-table" border>
      <el-table-column label="类型" show-overflow-tooltip min-width="80" align="center" class-name="required">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.productType"
            placeholder
            :disabled="(scope.row.productType == 1 && !scope.row.isSpellArk) || (scope.row.seqIndex && scope.row.seqIndex) >= 1"
            @change="changeType($event, scope.row)"
            size="mini"
            class="p8-select"
          >
            <el-option label="单品" :value="1"></el-option>
            <el-option label="件套" :value="2"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="柜子编号" prop="containerSeq" show-overflow-tooltip min-width="70" align="center" class-name="required"> </el-table-column>
      <el-table-column label="中文品名" show-overflow-tooltip min-width="103" align="center" class-name="required">
        <template v-slot="{ $index, row }">
          <el-form-item
            label
            :prop="'productDtos.' + $index + '.nameCn'"
            :rules="{
              required: true,
              message: ' ',
              trigger: ['blur', 'change'],
            }"
          >
            <el-autocomplete
              v-model="row.nameCn"
              :fetch-suggestions="row.productType === 1 ? queryProductLabelCn : queryProductPackageCn"
              @select="handleName($event, row)"
              value-key="plantNoNameCn"
              :disabled="
                (row.labelId && row.labelId !== '') || (row.packageId && row.packageId !== '') || (row.productType === 2 && !!row.nameCn && !!row.nameEn)
              "
              @blur="nameFocus(row)"
              :popper-append-to-body="true"
              size="mini"
              class="nameCn-list"
            >
              <template v-slot="{ item }">
                <el-tooltip :content="item.plantNoNameCn" :disabled="item.plantNoNameCn.length < 30">
                  <div class="name-tips">{{ item.plantNoNameCn }}</div>
                </el-tooltip>
              </template>
            </el-autocomplete>
            <span
              class="el-icon-circle-close name-close"
              v-if="(row.labelId && row.labelId !== '') || (row.packageId && row.packageId !== '') || (row.productType === 2 && !!row.nameCn && !!row.nameEn)"
              @click="clearNameLabel(row)"
            ></span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="英文品名" show-overflow-tooltip min-width="123" align="center" class-name="required">
        <template v-slot="{ row, $index }">
          <el-form-item
            label
            :prop="'productDtos.' + $index + '.nameEn'"
            :rules="{
              required: true,
              message: ' ',
              trigger: ['blur', 'change'],
            }"
          >
            <el-autocomplete
              v-model="row.nameEn"
              :fetch-suggestions="row.productType === 1 ? queryProductLabelEn : queryProductPackageEn"
              @select="handleName($event, row)"
              value-key="plantNoNameEn"
              :disabled="
                (row.labelId && row.labelId !== '') || (row.packageId && row.packageId !== '') || (row.productType === 2 && !!row.nameCn && !!row.nameEn)
              "
              @blur="nameFocus(row)"
              :popper-append-to-body="true"
              class="nameEn-list"
              size="mini"
            >
              <template v-slot="{ item }">
                <el-tooltip :content="item.plantNoNameEn" :disabled="item.plantNoNameEn.length < 30">
                  <div class="name-tips">{{ item.plantNoNameEn }}</div>
                </el-tooltip>
              </template>
            </el-autocomplete>
            <span
              class="el-icon-circle-close name-close"
              v-if="(row.labelId && row.labelId !== '') || (row.packageId && row.packageId !== '') || (row.productType === 2 && !!row.nameCn && !!row.nameEn)"
              @click="clearNameLabel(row)"
            ></span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="'价格(' + tradeCurrencyName + '/吨)'" min-width="95" align="center" class-name="required">
        <template v-slot="{ row, $index }">
          <el-form-item
            label
            :prop="'productDtos.' + $index + '.unitPrice'"
            :rules="{
              required: true,
              message: ' ',
              trigger: 'blur',
            }"
          >
            <el-input v-model="row.unitPrice" @blur="changeUnitPrice(row, $event)" :maxlength="9" size="mini"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="价格(元/吨)" min-width="85" align="center" class-name="required">
        <template v-slot="{ row, $index }">
          <el-form-item
            label
            :prop="'productDtos.' + $index + '.unitPriceRmb'"
            :rules="{
              required: true,
              message: ' ',
              trigger: ['blur', 'change'],
            }"
          >
            <el-input v-model="row.unitPriceRmb" :maxlength="6" @focus="showRate(row)" @blur="changeUnitPriceRmb(row, $event)" size="mini"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="柜数" min-width="70" align="center" class-name="required">
        <template v-slot="{ row, $index }">
          <el-form-item
            label
            :prop="'productDtos.' + $index + '.containerQuantity'"
            :rules="{
              required: true,
              message: ' ',
              trigger: 'blur',
            }"
          >
            <el-input
              v-if="row.productType === 2 || (row.productType === 1 && row.isSpellArk)"
              v-model="row.containerQuantity"
              @blur="changeSpellArkContainerOrDate(row, 'containerQuantity', $event)"
              :maxlength="7"
              size="mini"
            ></el-input>
            <span v-else>-</span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="每柜吨数" min-width="60" align="center">
        <template v-slot="{ row, $index }">
          <el-form-item label :prop="'productDtos.' + $index + '.weightPerContainer'">
            <el-input v-model="row.weightPerContainer" :maxlength="9" @blur="formatWeight($event, row)" size="mini"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="船期" min-width="105" align="center" class-name="required">
        <template v-slot="{ row, $index }">
          <el-form-item
            label
            :prop="'productDtos.' + $index + '.shippingDate'"
            :rules="{
              required: true,
              message: ' ',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="row.shippingDate"
              v-if="row.productType === 2 || (row.productType === 1 && row.isSpellArk)"
              @blur="changeSpellArkContainerOrDate(row, 'shippingDate', $event)"
              :maxlength="15"
              size="mini"
            ></el-input>
            <span v-else>-</span>
          </el-form-item>
        </template>
      </el-table-column>
      <!--新增标签、规格说明书、产品图片 -->
      <el-table-column label="标签" min-width="90" align="center">
        <template v-slot="{ row, $index }">
          <div v-if="row.productType === 2">
            <template v-if="row.nameCn !== null">
              <span class="spellrk-btn" @click="detailClick(row)">查看明细</span>
            </template>
            <template v-else>-</template>
          </div>
          <div v-else>
            <template v-if="row.url && row.url !== null">
              <span class="record__link" @click="labelClick(row, 1, $index)">查看</span>
            </template>
            <template v-else>-</template>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="规格说明书" min-width="100" align="center">
        <template v-slot="{ row }">
          <div v-if="row.productType === 2">
            <template v-if="row.nameCn !== null">
              <span class="spellrk-btn" @click="detailClick(row)">查看明细</span>
            </template>
            <template v-else>-</template>
          </div>
          <div v-else>
            <TableFileContent :readOnly="true" :fileUrlsArray="row.specificationAttachmentDtos" key-name="name" key-url="url" key-id="id"> </TableFileContent>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="产品图片" min-width="90" align="center">
        <template v-slot="{ row }">
          <div v-if="row.productType === 2">
            <template v-if="row.nameCn !== null">
              <span class="spellrk-btn" @click="detailClick(row)">查看明细</span>
            </template>
            <template v-else>-</template>
          </div>
          <div v-else>
            <TableFileContent :readOnly="true" :fileUrlsArray="row.productPictureAttachmentDtos" key-name="name" key-url="url" key-id="id"> </TableFileContent>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="100" align="center">
        <template v-slot="{ row, $index }">
          <div v-if="row.productType === 1 && row.isSpellArk">
            <span class="spellrk-btn" @click="addSpellArk(row, $index)">增加拼柜</span>
          </div>
          <div v-else>
            <!-- <div class="record__link" v-if="row.productType === 2 && row.packageId !== '' && row.packageId !== null" @click="getDetails(row)">查看明细</div>
            <div v-else>-</div> -->
            -
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="45">
        <template #header>
          <div class="el-icon-circle-plus-outline req" @click="addOfferProduct"></div>
        </template>
        <template v-slot="{ row, $index }">
          <div class="el-icon-remove-outline" @click="confirmEvent(row, $index)"></div>
        </template>
      </el-table-column>
    </el-table>
    <div class="offertable__flex">
      <div class="offertable__agencyFee">
        <div class="offertable__label">代理代采费</div>
        <el-form-item
          label
          prop="agencyFee"
          :rules="{
            required: true,
            message: ' ',
            trigger: 'blur',
          }"
        >
          <el-input v-model="data.agencyFee" size="small" style="width: 94px" @blur="changeAgencyFee($event)" :maxlength="4"></el-input>
        </el-form-item>
      </div>
      <div class="offertable__label" style="margin-left: 30px" v-show="rate.show">
        计算参数：关税 {{ rate.tariffRate }}{{ rate.tariffComputeMode == 1 ? '%' : '元/kg' }} &nbsp; 增值税 {{ rate.vatRate }}%
      </div>
    </div>
  </el-form>
</template>
<script>
import { erpConfirm, showFiles } from '@/utils/publicMethods';
let idIndex = 0;
export default {
  props: {
    formType: {
      type: [String, Number],
    },
    tradeCurrency: {
      // 大类
      type: [String, Number],
      default: '',
    },
    exchangeRate: {
      // 大类
      type: [String, Number],
      default: '',
    },
    agencyFee: {
      // 大类
      type: [String, Number],
      default: '',
    },
    category: {
      // 大类
      type: [String, Number],
      default: '',
    },
    country: {
      // 国家
      type: [String, Number],
      default: '',
    },
    plantNo: {
      // 厂号
      type: [String, Number, Array],
    },
    offerData: {
      // 表格信息
      type: [Object, Array],
      default: function () {
        return [];
      },
    },
  },
  created() {
    if (this.formType === 2) {
      // 为编辑时处理数据
      this.initEditData();
    }
  },
  watch: {
    agencyFee: {
      handler() {
        this.data.agencyFee = this.agencyFee;
      },
      immediate: true,
    },
    category: {
      handler() {
        if (this.formType === 1) {
          // 为新增时才重置
          this.restTable();
        }
      },
      immediate: true,
    },
    country: {
      handler() {
        if (this.formType === 1) {
          // 为新增时才重置
          this.restTable();
        }
      },
      immediate: true,
    },
    plantNo: {
      handler() {
        if (this.formType === 1) {
          // 为新增时才重置
          this.restTable();
        }
      },
      immediate: true,
    },
  },
  computed: {
    tradeCurrencyName() {
      const arr = ['', '美元', '澳元', '欧元'];
      return arr[this.tradeCurrency];
    },
  },
  data() {
    return {
      step: 1,
      rate: {
        show: false,
        vatRate: null,
        tariffRate: null,
        tariffComputeMode: null,
      },
      containerSeq: 1, // 初始柜号
      data: {
        agencyFee: null,
        productDtos: [
          {
            category: null,
            country: null,
            plantNo: null,
            isSpellArk: true, // 是否是拼柜
            idIndex: 0, // 自定义排序用id
            seqIndex: 0, // 拼柜新增索引
            spellArkId: 0, // 首行拼柜id
            containerQuantity: null,
            containerSeq: 1,
            identifier: '',
            labelId: null,
            packageId: null,
            nameCn: null,
            nameEn: null,
            productType: 1,
            shippingDate: null,
            tariffComputeMode: null,
            tariffRate: null,
            unitPrice: null,
            unitPriceRmb: null,
            vatRate: null,
            weightPerContainer: null,
          },
        ],
      },
      DialogData: {
        params: {},
        dialogVisible: false,
        title: '确定删除吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      },
      nameCnOptions: [],
      nameEnOptions: [],
    };
  },
  methods: {
    valid() {
      let tableData = null;
      this.$refs.tableForm.validate((valid) => {
        if (valid) {
          tableData = this.getOfferTabeInfo();
          if (tableData.productDtos.length > 0) {
            return tableData;
          } else {
            this.errorTip('请填写所有带星号的必填项');
          }
        } else {
          this.errorTip('请填写所有带星号的必填项');
        }
      });
      return tableData;
    },
    initEditData() {
      // 编辑时重新构建表格数据
      const formatData = JSON.parse(JSON.stringify(this.offerData));

      formatData.forEach((v) => {
        const customObj = JSON.parse(v.identifier);
        v = Object.assign(v, customObj);
      });

      idIndex = Math.max(...formatData.map((v) => v.idIndex)); // 找出最大值
      this.containerSeq = Math.max(...formatData.map((v) => v.containerSeq));
      this.data.productDtos = formatData;
    },
    changeType() {
      // eslint-disable-next-line prefer-rest-params
      const data = arguments[1];
      data.nameCn = null;
      data.nameEn = null;
      data.labelId = null;
      data.packageId = null;
      data.unitPriceRmb = null;
      data.vatRate = null;
      data.tariffRate = null;
    },
    getDetails(item) {
      if (item.id && item.id !== '' && !item.isEdit) {
        this.ajax({
          type: 'GET',
          url: '/malicrm/offer/noAuth/offerProductPartsDetail/' + item.id,
          loading: true,
          success: (res) => {
            if (res.code === 200) {
              const data = {
                partsName: item.nameCn,
                productPartsVos: res.result,
              };
              this.$emit('showDetails', data);
            }
          },
        });
      } else {
        // 新增查看件套详细 计算
        if (item.id && item.id !== '' && item.isEdit === true) {
          this.ajax({
            type: 'GET',
            url: '/malicrm/offer/noAuth/offerProductPartsDetail/' + item.id,
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                const showData = res.result;
                showData.forEach((v) => {
                  if (item.weightPerContainer === '' || item.weightPerContainer === null || item.weightPerContainer === 0) {
                    v.weight = '-';
                  } else {
                    v.weight = Number(this._formatFloat(item.weightPerContainer * (v.rate / 100), 6));
                  }
                });
                const data = {
                  partsName: item.nameCn,
                  productPartsVos: showData,
                };
                this.$emit('showDetails', data);
              }
            },
          });
        } else {
          this.ajax({
            type: 'GET',
            url: '/malicrm/label/getProductPackageByOfferId/' + item.packageId,
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                const showData = res.result.partsList;
                showData.forEach((v) => {
                  if (item.weightPerContainer === '' || item.weightPerContainer === null || item.weightPerContainer === 0) {
                    v.weight = '-';
                  } else {
                    v.weight = Number(this._formatFloat(item.weightPerContainer * (v.rate / 100), 6));
                  }
                });
                const data = {
                  partsName: res.result.nameCn,
                  productPartsVos: showData,
                };
                this.$emit('showDetails', data);
              }
            },
          });
        }
      }
    },
    queryProductLabelCn(value, cb) {
      // 搜索中文品名
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/queryOfferProductLabel',
        data: {
          nameCn: value,
          category: this.category,
          country: this.country,
          plantNo: this.plantNo,
        },
        success: (res) => {
          if (res.code === 200) {
            if (res.result !== null) {
              cb(res.result);
            } else {
              const arr = [];
              cb(arr);
            }
          }
        },
      });
    },
    queryProductLabelEn(value, cb) {
      // 搜索英文品名
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/queryOfferProductLabel',
        data: {
          nameEn: value,
          category: this.category,
          country: this.country,
          plantNo: this.plantNo,
        },
        success: (res) => {
          if (res.code === 200) {
            if (res.result !== null) {
              cb(res.result);
            } else {
              const arr = [];
              cb(arr);
            }
          }
        },
      });
    },
    queryProductPackageCn(value, cb) {
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/queryProductPackage',
        data: {
          nameCn: value,
          category: this.category,
          country: this.country,
          plantNo: this.plantNo,
        },
        success: (res) => {
          if (res.code === 200) {
            if (res.result !== null) {
              cb(res.result);
            } else {
              const arr = [];
              cb(arr);
            }
          }
        },
      });
    },
    queryProductPackageEn(value, cb) {
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/queryProductPackage',
        data: {
          nameEn: value,
          category: this.category,
          country: this.country,
          plantNo: this.plantNo,
        },
        success: (res) => {
          if (res.code === 200) {
            if (res.result !== null) {
              cb(res.result);
            } else {
              const arr = [];
              cb(arr);
            }
          }
        },
      });
    },
    handleName(item) {
      console.log('item', item);

      // 获取英文品名
      // eslint-disable-next-line prefer-rest-params
      const data = arguments[1];
      console.log('data', data);
      if (data.productType === 2) {
        if (item.hasDelLabel) {
          this.errorTip('件套里有已删除、已禁用、或审核不通过的品名，无法选择此件套。');
        } else {
          // 件套id
          data.id = null;
          data.nameCn = item.nameCn;
          data.nameEn = item.nameEn;
          data.category = item.category;
          data.country = item.country;
          data.plantNo = item.plantNo;
          data.packageId = item.id;
          data.url = item.url;
          data.productPictureAttachmentDtos = item.productPictureAttachmentDtos;
          data.specificationAttachmentDtos = item.specificationAttachmentDtos;
          data.isEdit = true;
          this.getRate(data);
        }
      } else {
        data.id = null;
        data.nameCn = item.nameCn;
        data.nameEn = item.nameEn;
        data.category = item.category;
        data.country = item.country;
        data.plantNo = item.plantNo;
        data.url = item.url;
        data.productPictureAttachmentDtos = item.productPictureAttachmentDtos;
        data.specificationAttachmentDtos = item.specificationAttachmentDtos;
        data.isEdit = true;
        data.labelId = item.id;
        this.getRate(data);
      }
    },
    nameFocus(item) {
      setTimeout(() => {
        if (!item.labelId && !item.packageId) {
          item.nameCn = null;
          item.nameEn = null;
        } else {
          return false;
        }
      }, 200);
    },
    clearNameLabel(item) {
      item.vatRate = null;
      item.unitPriceRmb = null;
      item.tariffRate = null;
      item.packageId = null;
      item.labelId = null;
      item.nameCn = null;
      item.nameEn = null;
    },

    getRate(item) {
      // 这里会直接修改原始值
      // 获取税率
      this.ajax({
        type: 'POST',
        url: 'malicrm/offer/getRate',
        data: {
          labelId: item.productType === 1 ? item.labelId : null,
          country: this.country,
          packageId: item.productType === 2 ? item.packageId : null, // 件套id还知道怎么获取
        },
        loading: true,
        success: (res) => {
          if (res.result) {
            item.vatRate = res.result.vatRate;
            item.tariffRate = res.result.tariffRate;
            item.tariffComputeMode = res.result.tariffComputeMode;
            item.unitPriceRmb = this.getRmbPrice(item);
          } else {
            item.vatRate = null;
            item.tariffRate = null;
            item.tariffComputeMode = null;
            item.unitPriceRmb = null;
          }
        },
      });
    },
    changePriceAll() {
      // 更新所有价格产品人民币价格
      const data = this.data.productDtos;
      data.forEach((v) => {
        // 循环更新所有数值
        v.unitPriceRmb = this.getRmbPrice(v);
      });
    },
    getRmbPrice(item) {
      // 更新单条价格信息
      const price = Number(item.unitPrice); // 美元/吨
      const agencyFee = Number(this.data.agencyFee); // 代理代采费
      const vatRate = Number(item.vatRate / 100); // 增值税
      const tariffRate = item.tariffComputeMode === 1 ? Number(item.tariffRate / 100) : Number(item.tariffRate); // 关税 这里要注意单位做个判断TODO
      const exchangeRate = Number(this.exchangeRate); // 汇率
      let rmbPrice;
      // 税率单位为%的

      if (item.unitPrice === null || item.unitPrice === '') {
        return null;
      }
      if (this.data.agencyFee === null || this.data.agencyFee === '' || this.exchangeRate === null || this.exchangeRate === '') {
        rmbPrice = null;
        return null;
      }

      if (this.data.agencyFee >= 0 || this.exchangeRate >= 0) {
        // 判断汇率 代理代采费修改
        if (item.vatRate === null) {
          rmbPrice = null;
        } else {
          if (item.tariffComputeMode === 1) {
            // 不同种关税计算
            rmbPrice = price * exchangeRate * (1 + tariffRate) * (1 + vatRate) + agencyFee;
            rmbPrice = rmbPrice.toFixed(0);
          } else {
            // 为千克的关税单位
            rmbPrice = (price * exchangeRate + 1000 * tariffRate) * (1 + vatRate) + agencyFee;
            rmbPrice = rmbPrice.toFixed(0);
          }
        }
      }

      return rmbPrice;
    },
    // 点击标签
    labelClick(row, type, index) {
      // 标签
      if (type === 1) {
        window.open(row.url, '_blank');
      } else if (type === 2) {
        // 规格说明书
        let { name, url } = row.specificationAttachmentDtos[index];
        showFiles(name, url);
      } else {
        // 产品图片
        let { name, url } = row.productPictureAttachmentDtos[index];
        showFiles(name, url);
      }
    },
    // 查看明细
    detailClick(row) {
      this.getDetails(row);
    },
    changeUnitPrice(item, e) {
      console.log(111);
      // 计算获取当前人民币价格

      let value = e.target.value; // 清除“数字”和“.”以外的字符
      value = value.replace(/[^\d.]/g, '');
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 999999.99) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 6);
        }
      }

      item.unitPrice = value;
      if (item.vatRate !== null) {
        item.unitPriceRmb = this.getRmbPrice(item);
      }
    },
    changeUnitPriceRmb(item, e) {
      // 格式化人民币价格
      const value = this.formatPrice(e.target.value);
      item.unitPriceRmb = value;
      this.rate.show = false;
    },
    showRate(item) {
      if (item.tariffRate === null || item.tariffRate === '') {
        this.rate.show = false;
      } else {
        this.rate.tariffComputeMode = item.tariffComputeMode;
        this.rate.vatRate = item.vatRate;
        this.rate.tariffRate = item.tariffRate;
        this.rate.show = true;
      }
    },
    changeAgencyFee(e) {
      // 代理代采费更新过滤
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字”
      this.data.agencyFee = value;
      this.changePriceAll(); // 更新所有价格
    },
    restTable() {
      // 重置数据
      idIndex = 0;
      this.containerSeq = 1;
      this.data.productDtos = [
        {
          category: null,
          country: null,
          plantNo: null,
          isSpellArk: true, // 是否是拼柜
          idIndex: 0, // 自定义排序用id
          seqIndex: 0, // 拼柜新增索引
          spellArkId: 0, // 首行拼柜id
          containerQuantity: null,
          containerSeq: 1,
          identifier: '',
          labelId: null,
          packageId: null,
          nameCn: null,
          nameEn: null,
          productType: 1,
          shippingDate: null,
          tariffComputeMode: null,
          tariffRate: null,
          unitPrice: null,
          unitPriceRmb: null,
          vatRate: null,
          weightPerContainer: null,
        },
      ];
    },
    addOfferProduct() {
      // 新增报盘产品明细
      this.containerSeq += 1; // 柜号自增
      idIndex = idIndex + 1; // 只增不减
      const data = this.data.productDtos;
      const item = {
        category: null,
        country: null,
        plantNo: null,
        isSpellArk: true, // 是否是拼柜
        seqIndex: 0, // 拼柜新增索引
        idIndex: idIndex,
        containerQuantity: null,
        containerSeq: this.containerSeq,
        identifier: '',
        labelId: null,
        packageId: null,
        nameCn: null,
        nameEn: null,
        productType: 1,
        shippingDate: null,
        tariffComputeMode: null,
        tariffRate: null,
        unitPrice: null,
        unitPriceRmb: null,
        vatRate: null,
        weightPerContainer: null,
      };
      data.push(item);
    },
    confirmEvent(item, index) {
      if (!item.containerQuantity && !item.nameCn && !item.nameEn && !item.shippingDate && !item.unitPrice && !item.unitPriceRmb && !item.weightPerContainer) {
        this.deleteOfferProduct(item, index);
      } else {
        erpConfirm('确定删除吗？').then(() => {
          // 删除确认框操作
          this.deleteOfferProduct(item, index);
        });
      }
    },
    deleteConfirm() {
      // 删除确认框操作
      const { item, index } = this.DialogData.params;
      this.deleteOfferProduct(item, index);
    },
    deleteOfferProduct(item, index) {
      this.DialogData.dialogVisible = false;
      // 删除报盘产品信息
      const data = this.data.productDtos;
      if (item.productType === 2 || (item.seqIndex && item.seqIndex === 0) || (item.isSpellArk && !item.seqIndex)) {
        // 类型为件套 或 单品没有添加拼柜或 添加过拼柜但删除只剩一个
        data.forEach((v, i) => {
          // 更新柜号索引
          if (i >= index) {
            // 只修改当前删除后的索引值
            v.containerSeq -= 1;
          }
        });
        this.containerSeq -= 1; // 删除一个 递增索引也要减1
        data.splice(index, 1);
      } else {
        // 删除单品拼柜信息
        if (item.isSpellArk) {
          // 如果是平柜的第一条数据
          const nextItem = data[index + 1];
          nextItem.isSpellArk = true;
          nextItem.seqIndex = item.seqIndex - 1;
          data.forEach((v) => {
            if (v.spellArkId === item.idIndex) {
              v.spellArkId = nextItem.idIndex; // 关联id换为下一条id
              v.containerQuantity = nextItem.containerQuantity;
              v.shippingDate = nextItem.shippingDate;
            } else {
              return false;
            }
          });
          data.splice(index, 1);
        } else {
          // 是单个拼柜数据
          const spellArkId = item.spellArkId; // 获取拼柜的主id
          const firstSpellArkIndex = data.findIndex((v) => v.idIndex === spellArkId); // 找到平柜的第一条记录的位置

          data[firstSpellArkIndex].seqIndex = data[firstSpellArkIndex].seqIndex - 1;
          data.splice(index, 1);
        }
      }
    },
    addSpellArk(item, index) {
      // 添加拼柜信息
      idIndex = idIndex + 1; // 只增不减
      item.isSpellArk = true; // 修改当前拼柜为首行
      if (item.seqIndex) {
        // 临时记录拼柜信息索引，如果不加就会倒序处理
        item.seqIndex += 1;
      } else {
        item.seqIndex = 1;
      }
      const data = this.data.productDtos;
      const params = {
        category: null,
        country: null,
        plantNo: null,
        isSpellArk: false, // 是否是拼柜
        seqIndex: 0, // 拼柜新增索引
        idIndex: idIndex,
        spellArkId: item.idIndex, // 记录下关联的第一条拼柜信息
        containerQuantity: item.containerQuantity,
        containerSeq: item.containerSeq,
        identifier: '',
        labelId: null,
        packageId: null,
        nameCn: null,
        nameEn: null,
        productType: 1,
        shippingDate: item.shippingDate,
        tariffComputeMode: null,
        tariffRate: null,
        unitPrice: null,
        unitPriceRmb: null,
        vatRate: null,
        weightPerContainer: null,
      };
      data.splice(index + item.seqIndex, 0, params);
    },
    changeSpellArkContainerOrDate(item, name, e) {
      // 改变柜数后更新关联值
      const data = this.data.productDtos;
      let value = e.target.value;
      if (name === 'containerQuantity') {
        value = value.replace(/[^\d-]/g, ''); // 清除“数字以外的字符
        if (value !== '' && !isNaN(Number(value))) {
          value = Number(value);
        }
      } else {
        value = value.replace(/[a-zA-Z]/g, ''); // 清除中文英文以外的字符
      }

      item[name] = value;
      data.forEach((v) => {
        // 更新拼柜数据
        if (v.spellArkId === item.idIndex) {
          v.containerQuantity = item.containerQuantity;
          v.shippingDate = item.shippingDate;
        } else {
          return false;
        }
      });
    },
    getOfferTabeInfo() {
      // 获取处理完自定义参数后的数据,统一从该方法出去
      const data = this.data.productDtos;
      data.forEach((v) => {
        v.identifier = {
          isSpellArk: v.isSpellArk, // 是否是拼柜
          seqIndex: v.seqIndex, // 拼柜新增索引
          idIndex: v.idIndex,
          spellArkId: v.spellArkId, // 记录下关联的第一条拼柜信息
        };
        if (v.weightPerContainer === null) {
          v.weightPerContainer = '';
        }
        v.identifier = JSON.stringify(v.identifier);
      });
      return this.data;
    },
    formatWeight(e, item) {
      // 格式化每柜吨数
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      if (value !== '') {
        value = Number(value);
      }
      item.isEdit = true;
      item.weightPerContainer = value;
    },
    formatPrice(value) {
      value = value.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
      return value;
    },
  },
};
</script>
<style lang="scss">
.offer-table-list {
  input {
    padding: 8px !important;
  }
}
</style>
<style lang="scss" scoped>
.productCate__table {
  width: 100%;
  background: #fff;
  border-right: 1px solid #e3e4e6;
  border-bottom: 1px solid #e3e4e6;
  box-sizing: border-box;

  .center {
    text-align: center;
  }

  th {
    border-left: 1px solid #e3e4e6;
    border-top: 1px solid #e3e4e6;
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #333;
    vertical-align: middle;
    font-weight: 400;
    .required {
      &::after {
        content: '*';
        display: inline-block;
        position: relative;
        color: #ff6c2b;
        font-size: 24px;
        vertical-align: middle;
        top: 3px;
        left: 7px;
      }
    }
  }

  td {
    border-left: 1px solid #e3e4e6;
    border-top: 1px solid #e3e4e6;
    padding: 6px;
    color: #333;

    &.port__inner {
      padding: 12px 10px;
      text-align: center;
      word-wrap: break-word;
      line-height: 1.25;
    }

    .text-overflow {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-input__inner {
      color: #1a1a1a;
      padding: 0 10px;
      font-size: 14px;
    }
  }

  .from__Detailed_locn {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .th-48 {
    width: 48px;
  }

  .th-120 {
    width: 120px;
  }

  .th-237 {
    width: 237px;
  }

  .th-320 {
    width: 320px;
  }

  .th-140 {
    width: 140px;
  }

  .th-160 {
    width: 160px;
  }

  .th-200 {
    width: 200px;
  }

  .opt {
    cursor: pointer;
    text-align: center;

    [class^='el-icon-'] {
      font-size: 20px;
    }
  }
}
.name-tips {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offertable__flex {
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: 50px;
  background: #fff;
  padding-left: 20px;
  border: 1px solid #e3e4e6;
  border-top: none;
}
.offertable__agencyFee {
  display: flex;
  align-items: center;
}
.offertable__label {
  font-size: 16px;
  margin-right: 10px;
}

.req {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-icon-circle-plus-outline {
  font-size: 20px;
  cursor: pointer;
}
.el-icon-remove-outline {
  font-size: 20px;
  cursor: pointer;
}

.productCate__table th .req::after {
  display: flex;
  align-items: center;
  content: '*';
  font-size: 24px;
  color: #ff6c2b;

  position: relative;
  left: 3px;
  top: 5px;
}
.productCate__table td {
  text-align: center;
  vertical-align: middle;
}
.name-close {
  position: absolute;
  cursor: pointer;
  right: 7px;
  top: 15px;
}
.spellrk-btn {
  background: rgba(19, 131, 242, 0.1);
  color: #2878ff;
  font-size: 14px;
  display: block;
  cursor: pointer;
  width: 76px;
  height: 24px;
  border: 1px solid rgba(19, 131, 242, 0.1);
  border-radius: 2px;
  line-height: 22px;
  text-align: center;
  margin: 0 auto;
}
.el-form-item {
  margin-bottom: 0;
}
</style>
