import { reactive, ref, toRefs } from 'vue';
type FormData = {
  agencyFee: string | number;
  category: string | number;
  country: string | number | null;
  exchangeRate: number;
  supplierId: number | string;
  supplierPaymentRatio: number | string;
  tradeCurrency: number;
  id: number | string;
  incoterm: string;
  plantNo: any[];
  productDtos: any[];
  url: string;
  foreignOfferRecord: any[];
  specifications: any[];
  version: null | string | number;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const offerTableRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    agencyFee: '',
    category: '',
    country: '',
    exchangeRate: 0,
    supplierId: null,
    supplierPaymentRatio: '',
    tradeCurrency: 1,
    id: null,
    incoterm: null,
    plantNo: [],
    productDtos: [],
    url: process.env.VUE_APP_H5_URL + '/pages/offerh5/index?id=',
    foreignOfferRecord: [],
    specifications: [],
    version: null,
    ...params,
  });
  const customFormData = reactive({
    countryList: [],
    plantList: [],
    supplierList: [],
    woollyDetail: {
      partsName: '',
      productPartsVos: [],
    },
    showType: 1,
    status: false,
    foreignOfferRecord: [],
    specifications: [],
    fileType: 'crmOfferStandard',
  });
  const rules = reactive({
    category: [{ required: true, message: '请选择大类', trigger: 'change' }],
    country: [{ required: true, message: '请选择国家', trigger: 'change' }],
    plantNo: [{ required: true, message: '请选择厂号', trigger: 'change' }],
    supplierId: [{ required: true, message: '请选择供应商', trigger: 'change' }],
    incoterm: [{ required: true, message: '请选择Incoterm', trigger: 'change' }],
    supplierPaymentRatio: [{ required: true, message: '请输入付款条件', trigger: 'blur' }],
    tradeCurrency: [{ required: true, message: '请选择交易币种', trigger: 'change' }],
    exchangeRate: [{ required: true, message: '请输入汇率', trigger: 'blur' }],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const changePaymentRatio = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入两个小数
    value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
    if (value.indexOf('.') < 0 && value !== '') {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) === 0) {
        value = value.substr(1);
      }
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.replace('.', '');
    }
    if (value >= 100) {
      value = 100;
    }
    formData[prop] = value;
  };
  return {
    rules,
    formData,
    formRef,
    offerTableRef,
    setFormData,
    ...toRefs(customFormData),
    changePaymentRatio,
  };
};
