
import { httpGet, httpPost } from '@/api';
import FileContent from '@/components/FileContent/FileContent.vue';
import { ajaxLoading, errorMessage, setTitleLabel } from '@/utils/publicMethods';
import { defineComponent, computed } from 'vue';
import useFormData from './useOfferFormData';
import OfferTable from '../OfferTable/OfferTable.vue';
import WoollyDetail from '../WoollyDetail/WoollyDetail.vue';
import bigDecimal from 'js-big-decimal';
type RateType = 1 | 2 | 3;
type RateResult = {
  rate: Record<RateType, number>;
  updateTime: string;
};
export default defineComponent({
  components: { FileContent, OfferTable, WoollyDetail },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    let params: any = {};
    if (props.formType === 2) {
      params = Object.assign({}, props.data);
    }
    const {
      rules,
      formData,
      formRef,
      offerTableRef,
      setFormData,
      countryList,
      plantList,
      supplierList,
      changePaymentRatio,
      status,
      woollyDetail,
      showType,
      specifications,
      foreignOfferRecord,
    } = useFormData(params);
    /** 获取文件信息 */
    const getForeignOfferRecords = (fileList: any[]) => {
      const arr: any[] = [];
      fileList.forEach((v, i) => {
        const params = {
          name: fileList[i].name,
          url: fileList[i].url,
        };
        arr.push(params);
      });
      formData.foreignOfferRecord = arr;
    };
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      const params = {
        ...formData,
      };
      (params as any).plantNo = params.plantNo.join('、');
      ajaxLoading.lock();
      const res = await httpPost('malicrm/offer/operateOffer', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      console.log(formData.specifications);
      formRef.value.validate((valid) => {
        if (valid) {
          const res = offerTableRef.value.valid();
          if (res !== null && res.productDtos && res.productDtos.length > 0) {
            formData.productDtos = Object.assign([], res.productDtos);
            formData.agencyFee = res.agencyFee;
            submitData();
          }
        } else {
          return false;
        }
      });
    };
    const closeWoolyDetail = () => {
      showType.value = 1;
    };
    const showDetails = (data) => {
      localStorage.setItem('showDetail', 'true');
      woollyDetail.value = data;
      showType.value = 2;
    };
    const getPlantNo = async () => {
      // 获取厂号
      const params = {
        category: formData.category,
        country: formData.country,
        plantNo: null,
      };
      const res = await httpPost('/malicrm/approval/queryPlantNo', params);
      if (res.code === 200) {
        plantList.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const changeCountry = () => {
      formData.plantNo = [];
      plantList.value = [];
      getPlantNo();
    };
    const getDefaultAgencyFee = async () => {
      const res = await httpGet('malicrm/simple/noAuth/getConsValueIncache?key=default_agency_fee');
      if (res.code === 200) {
        formData.agencyFee = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const getSpecifications = (fileList: any[]) => {
      const arr: any[] = [];
      fileList.forEach((v, i) => {
        const params = {
          name: fileList[i].name,
          url: fileList[i].url,
        };
        arr.push(params);
      });
      formData.specifications = arr;
    };
    const formatRate = (e,v) => {
      // 格式化汇率格式
      let value = v.toString();
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100) {
        value = 100;
      }
      formData.exchangeRate = value;
      offerTableRef.value.changePriceAll();
    };
    const changeRate = async (type: RateType) => {
      // 获取最新汇率
      const res = await httpGet('/malicrm/common/getPrescribedRate');
      if (res.code === 200) {
        const result = res.result as RateResult;
        formData.exchangeRate = Number(bigDecimal.subtract(result.rate[type], 0.05));
        formatRate('',formData.exchangeRate);
        offerTableRef.value.changePriceAll();
      } else {
        errorMessage(res.message);
      }
    };
    const getSupplierList = async (value) => {
      const params = {
        supplierName: value,
      };
      const res = await httpPost('malicrm/offer/querySupplierBySupplierName', params);
      if (res.code === 200) {
        supplierList.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const getCountry = async () => {
      const params = {
        category: formData.category,
        country: null,
        plantNo: null,
      };
      const res = await httpPost('malicrm/approval/queryCountry', params);
      if (res.code === 200) {
        countryList.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const changeCateGory = () => {
      formData.country = null;
      formData.plantNo = [];
      countryList.value = [];
      plantList.value = [];
      getCountry();
    };
    if (props.formType === 1) {
      getDefaultAgencyFee();
      getSupplierList('');
      changeRate(1); // 获取最新汇率
      status.value = true;
    } else {
      const data = JSON.parse(JSON.stringify(props.data));
      data.productDtos = JSON.parse(JSON.stringify(data.productVoList));
      delete data.productVoList;
      data.plantNo = data.plantNo.split('、');

      setFormData(data);
      woollyDetail.value = data;
      specifications.value = data.specifications;
      foreignOfferRecord.value = data.foreignOfferRecord;
      getSupplierList(data.supplierName);
      getCountry();
      getPlantNo();
      status.value = true;
    }
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      offerTableRef,
      setTitleLabel,
      getForeignOfferRecords,
      readOnly,
      changeCateGory,
      countryList,
      plantList,
      getCountry,
      getSupplierList,
      supplierList,
      changePaymentRatio,
      changeRate,
      formatRate,
      getSpecifications,
      status,
      getDefaultAgencyFee,
      changeCountry,
      getPlantNo,
      woollyDetail,
      showType,
      showDetails,
      closeWoolyDetail,
      specifications,
      foreignOfferRecord,
    };
  },
});
