<template>
  <div class="woolly-warp">
    <div class="woolly-title">{{ data.partsName }}明细</div>
    <el-table :data="data.productPartsVos" border class="view-table">
      <el-table-column align="center" prop="nameCn" label="中文品名" width="259" show-overflow-tooltip> </el-table-column>
      <el-table-column align="center" prop="nameEn" label="英文品名" width="310" show-overflow-tooltip> </el-table-column>
      <el-table-column align="center" prop="rate" label="比例(%)"> </el-table-column>
      <el-table-column align="center" prop="weight" label="重量(吨)">
        <template v-slot="scope">
          <span v-if="scope.row.weight !== null && scope.row.weight !== ''">
            {{ scope.row.weight }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="标签" width="139" v-if="type !== 1">
        <template v-slot="scope">
          <span class="record__link" @click="clickTag(scope.row, 1, scope.$index)" v-if="scope.row.labelUrl !== null && scope.row.labelUrl !== ''">查看</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="规则说明书" width="139" v-if="type !== 1">
        <template v-slot="scope">
          <TableFileContent :readOnly="true" :fileUrlsArray="scope.row.specificationAttachmentDtos" key-name="name" key-url="url" key-id="id">
          </TableFileContent>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品图片" width="139" v-if="type !== 1">
        <template v-slot="scope">
          <TableFileContent :readOnly="true" :fileUrlsArray="scope.row.productPictureAttachmentDtos" key-name="name" key-url="url" key-id="id">
          </TableFileContent>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :append-to-body="true" v-model:visible="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <div class="crm-button__group" v-if="type > 1">
      <button class="crm-form__button primary" @click="submit">关闭</button>
    </div>
  </div>
</template>
<script>
import { showFiles } from '@/utils/publicMethods';
export default {
  props: {
    data: {
      type: [Object],
      default: function () {
        return {
          partsName: '',
          productPartsVos: [],
        };
      },
    },
    type: {
      type: [String, Number],
    },
  },
  components: {},
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: null,
    };
  },
  methods: {
    submit() {
      this.$emit('cancel', true);
    },
    clickTag(row, type, index) {
      // 标签
      if (type === 1) {
        window.open(row.labelUrl, '_blank');
      } else if (type === 2) {
        // 规格说明书
        let { name, url } = row.specificationAttachmentDtos[index];
        showFiles(name, url);
      } else {
        // 产品图片
        let { name, url } = row.productPictureAttachmentDtos[index];
        showFiles(name, url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.woolly-title {
  color: #333;
  font-size: 16px;
  margin-bottom: 11px;
}
.woolly-warp {
  margin-bottom: 20px;
}
</style>
